import { v4 as uuid } from "uuid";

import {
  ADD_NOTIFICATION,
  END_TASK,
  REMOVE_NOTIFICATION,
  START_TASK,
} from "./actions";

/** Etat initial */
const initialState = {
  loading: 0,
  messages: [],
};

/**
 * Common reducer. Handle notifications + loading indicator.
 * @param {*} state Current state
 * @param {*} action action with payload
 */
export function commonReducer(state = initialState, action) {
  let loading = state.loading;

  switch (action.type) {
    case START_TASK:
      return {
        ...state,
        loading: loading + 1,
      };
    case END_TASK:
      return {
        ...state,
        loading: loading - 1,
      };
    case ADD_NOTIFICATION:
      loading--;
      const messages = state.messages.slice();
      messages.push({
        id: uuid(),
        message: action.message,
        level: action.level,
      });
      return {
        ...state,
        loading: loading > 0 ? loading : 0,
        messages,
      };
    case REMOVE_NOTIFICATION:
      const arrayTemp = state.messages.slice();
      arrayTemp.splice(arrayTemp.indexOf(action.id), 1);
      return { ...state, messages: arrayTemp };
    default:
      return state;
  }
}
