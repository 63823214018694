import Immutable from "seamless-immutable";
import { SET_DROPDOWNS, SET_DROPDOWNS_PAGE } from "./action";
const initialState = {
  dropDowns: Immutable([]),
  page: {
    size: 20,
    totalElements: undefined,
    number: undefined,
  },
};

export const dropDownsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DROPDOWNS:
      return Immutable.setIn(state, ["dropDowns"], action.dropDowns);
    case SET_DROPDOWNS_PAGE:
      return Immutable.setIn(state, ["page"], action.page);
    default:
      return state;
  }
};
