import {
  SET_MISSIONS,
  SET_MISSION_PAGE,
  ADD_MISSION,
  SET_EVENTS,
  EDIT_MISSION,
  SET_MISSIONS_NOT_ASSIGN,
  SET_MISSION_PAGE_NOT_ASSIGN,
  SAVE_MISSION
} from "./actions";
import Immutable from "seamless-immutable";
/** Initial state */
const EMPTY_ARRAY = Immutable([]);
const initialState = Immutable({
  missions: EMPTY_ARRAY,
  missions_not_assign: EMPTY_ARRAY,
  page: undefined,
  page_not_assign: undefined,
  events: EMPTY_ARRAY,
  mission: undefined,
  mission_save: undefined
});

export function missionReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_MISSION:
      return Immutable.set(state, "mission_save", action.mission);
    case SET_MISSIONS:
      return Immutable.set(state, "missions", action.missions);
    case SET_MISSIONS_NOT_ASSIGN:
      return Immutable.set(state, "missions_not_assign", action.missions);
    case ADD_MISSION:
      return Immutable.set(
        state,
        "missions",
        state.missions.concat(action.mission),
      );
    case SET_MISSION_PAGE:
      return Immutable.set(state, "page", action.page);
    case SET_MISSION_PAGE_NOT_ASSIGN:
      console.log("red", action.page)
      return Immutable.set(state, "page_not_assign", action.page);
    case SET_EVENTS:
      return Immutable.set(state, "events", action.events);
    case EDIT_MISSION:
      let index = -1;
      state.missions.find((elt, i) => {
        if (elt.id === action.mission.id) {
          index = i;
          return true;
        }
        return false;
      });
      return index >= 0
        ? Immutable.setIn(state, ["missions", index], action.mission)
        : state;
    default:
      return state;
  }
}
