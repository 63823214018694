import jwtDecode from 'jwt-decode';
import {LOGIN,LOGOUT} from "./actions";

const jwtDetails = (token) => {
    if (token == null) {
        return {
            username: undefined,
            userid: undefined,
            roles: []
        }
    }
    const jwt = jwtDecode(token);
    return {
        userid: jwt.sub,
        username: jwt.username,
        roles: jwt.roles
    }
}

const initialState = {
    sessionToken: sessionStorage.getItem("sessionToken"),
    details: jwtDetails(sessionStorage.getItem("sessionToken"))
}

const authentificationReducer = (state = initialState, action) => {
    switch(action.type) {
        case LOGIN:
            return {
                ...state,
                sessionToken: action.sessionToken,
                details: jwtDetails(action.sessionToken)
            }
        case LOGOUT:
            return {
                ...state,
                sessionToken: undefined
            }
        default:
            return state;
    }
}
export default authentificationReducer;