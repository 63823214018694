export const SET_DROPDOWNS = "SET_DROPDOWNS";
export const SET_DROPDOWNS_PAGE = "SET_DROPDOWNS_PAGE";
export const setDropDowns = dropDowns => {
  return {
    type: SET_DROPDOWNS,
    dropDowns: dropDowns,
  };
};

export const setDropDownsPage = page => {
  return {
    type: SET_DROPDOWNS_PAGE,
    page: page,
  };
};
