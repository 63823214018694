import {
  ADD_USER,
  SET_CURRENT_USER,
  SET_USERS,
  SET_USER_PAGE,
  EDIT_USER,
  REMOVE_USER,
} from "./actions";
import Immutable from "seamless-immutable";

const EMPTY_ARRAY = Immutable([]);
const initialState = {
  users: EMPTY_ARRAY,
  currentUser: undefined,
  page: {
    size: 20,
    totalElements: undefined,
    number: undefined,
  },
};
export function userReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_USER:
      return Immutable.set(state, "users", state.users.concat(action.user));
    case SET_USERS:
      return Immutable.set(state, "users", action.users);
    case SET_CURRENT_USER:
        return Immutable.set(state, "currentUser", action.user);
    case EDIT_USER:
      const index = state.users.indexOf(action.user);
      return Immutable.setIn(state, ["users", index], action.user);
    case REMOVE_USER:
      return Immutable.set(
        state,
        "users",
        state.users.filter(user => user.id !== action.id),
      );
    case SET_USER_PAGE:
      return Immutable.set(state, "page", action.page);
    default:
      return state;
  }
}
