export const SET_TABLEMODELS = "SET_TABLEMODELS";
export const SET_TABLEMODELS_PAGE = "SET_TABLEMODELS_PAGE";
export const setTableModels = tableModels => {
  return {
    type: SET_TABLEMODELS,
    tableModels: tableModels,
  };
};

export const setTableModelsPage = page => {
  return {
    type: SET_TABLEMODELS_PAGE,
    page: page,
  };
};
