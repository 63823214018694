export const SET_ACTIVE_PART = "SET_ACTIVE_PART";
export const ADD_SCHEMA = "ADD_SCHEMA";
export const DELETE_SCHEMA = "DELETE_SCHEMA";
export const UPDATE_SCHEMA = "UPDATE_SCHEMA";
export const SET_UISCHEMA = "SET_UISCHEMA";
export const SET_JSONSCHEMA = "SET_JSONSCHEMA";
export const MOVE_SECTION_SCHEMA = "MOVE_SECTION_SCHEMA";

export const moveSectionSchema = (path, srcIndex, destIndex) => {
  return {
    type: MOVE_SECTION_SCHEMA,
    path,
    srcIndex,
    destIndex,
  };
};
export const setActivePart = activePart => {
  return {
    type: SET_ACTIVE_PART,
    activePart: activePart,
  };
};
export const setJsonSchema = jsonSchema => {
  return {
    type: SET_JSONSCHEMA,
    jsonSchema: jsonSchema,
  };
};
export const setUiSchema = uiSchema => {
  return {
    type: SET_UISCHEMA,
    uiSchema: uiSchema,
  };
};
export const addSchema = (path, schema, uiSchema) => {
  return {
    type: ADD_SCHEMA,
    path: path,
    schema: schema,
    uiSchema: uiSchema,
  };
};

export const deleteSchema = path => {
  return {
    type: DELETE_SCHEMA,
    path: path,
  };
};

export const updateSchema = (oldPath, newPath, field, uiSchema) => {
  return {
    type: UPDATE_SCHEMA,
    field: field,
    oldPath: oldPath,
    newPath: newPath,
    uiSchema: uiSchema,
  };
};
