export const SET_CONDITIONALFIELDS = "SET_CONDITIONALFIELDS";
export const SET_CONDITIONALFIELDS_PAGE = "SET_CONDITIONALFIELDS_PAGE";
export const setConditionalFields = conditionalFields => {
  return {
    type: SET_CONDITIONALFIELDS,
    conditionalFields: conditionalFields,
  };
};

export const setConditionalFieldsPage = page => {
  return {
    type: SET_CONDITIONALFIELDS_PAGE,
    page: page,
  };
};
