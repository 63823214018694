// Action types
export const START_TASK = "common/START_TASK";
export const END_TASK = "common/END_TASK";
export const ADD_NOTIFICATION = "common/ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "common/REMOVE_NOTIFICATION";
export const LOAD_PROMISE = "common/LOAD_PROMISE";
export const CLEAN_LOAD_PROMISE = "common/CLEAN_LOAD_PROMISE";
// Notification levels
export const LEVEL_INFO = "info";
export const LEVEL_WARNING = "warning";
export const LEVEL_ERROR = "error";
export const LEVEL_FATAL = "fatal";

// Action functions
export const startTask = () => ({ type: START_TASK });
export const endTask = () => ({ type: END_TASK });
export const notifyInfo = message => ({
  type: ADD_NOTIFICATION,
  message,
  level: LEVEL_INFO,
});
export const notifyWarning = message => ({
  type: ADD_NOTIFICATION,
  message,
  level: LEVEL_WARNING,
});
export const notifyError = message => ({
  type: ADD_NOTIFICATION,
  message,
  level: LEVEL_ERROR,
});
export const notificationFatal = message => ({
  type: ADD_NOTIFICATION,
  message,
  level: LEVEL_FATAL,
});
export const removeNotification = id => ({ type: REMOVE_NOTIFICATION, id });
