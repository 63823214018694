export const ADD_TEMPLATE = "ADD_TEMPLATE";
export const ALL_TEMPLATE = "ALL_TEMPLATE";
export const ALL_DOCUMENT = "ALL_DOCUMENT";
export const SET_TEMPLATES = "SET_TEMPLATES";
export const SET_TEMPLATE_PAGE = "SET_TEMPLATE_PAGE";
export const addTemplate = template => {
  return {
    type: ADD_TEMPLATE,
    template: template,
  };
};

export const setTemplatePage = page => {
  return {
    type: SET_TEMPLATE_PAGE,
    page: page,
  };
};

export const setTemplates = templates => {
  return {
    type: SET_TEMPLATES,
    templates: templates,
  };
};

export const setAllTemplate = templates => {
  return {
    type: ALL_TEMPLATE,
    templates: templates,
  };
};

export const setAllDocs = documents => {
  return {
    type: ALL_DOCUMENT,
    documents: documents,
  };
};
