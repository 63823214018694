import Immutable from "seamless-immutable";
import { SET_TABLEMODELS, SET_TABLEMODELS_PAGE } from "./action";
const initialState = {
  tableModels: Immutable([]),
  page: {
    size: 20,
    totalElements: undefined,
    number: undefined,
  },
};

export const tableModelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TABLEMODELS:
      return Immutable.setIn(state, ["tableModels"], action.tableModels);
    case SET_TABLEMODELS_PAGE:
      return Immutable.setIn(state, ["page"], action.page);
    default:
      return state;
  }
};
