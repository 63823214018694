export const SET_MISSIONS = "SET_MISSIONS";
export const SET_MISSIONS_NOT_ASSIGN = "SET_MISSIONS_NOT_ASSIGN";
export const SET_MISSION_PAGE = "SET_MISSION_PAGE";
export const SET_MISSION_PAGE_NOT_ASSIGN = "SET_MISSION_PAGE_NOT_ASSIGN";
export const ADD_MISSION = "ADD_MISSION";
export const SAVE_MISSION = "SAVE_MISSION";
export const EDIT_MISSION = "EDIT_MISSION";
export const SET_EVENTS = "SET_EVENTS";
export const setEvents = events => {
  return {
    type: SET_EVENTS,
    events: events,
  };
};
export const setMissions = missions => {
  return {
    type: SET_MISSIONS,
    missions: missions,
  };
};

export const setMissionsNotAssign = missions => {
  return {
    type: SET_MISSIONS_NOT_ASSIGN,
    missions: missions,
  };
};

export const editMission = mission => {
  return {
    type: EDIT_MISSION,
    mission: mission,
  };
};

export const saveMission = mission => {
  return {
    type: SAVE_MISSION,
    mission: mission,
  };
};

export const addMission = mission => {
  return {
    type: ADD_MISSION,
    mission: mission,
  };
};

export const setMissionPage = page => {
  return {
    type: SET_MISSION_PAGE,
    page: page,
  };
};

export const setMissionPageNotAssign = page => {
  return {
    type: SET_MISSION_PAGE_NOT_ASSIGN,
    page: page,
  };
};