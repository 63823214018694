import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import Loadable from "react-loadable";
import "./App.scss";
// Redux
import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { missionReducer } from "./redux/missions/reducer";
import { commonReducer } from "./redux/common/reducer";
import { userReducer } from "./redux/users/reducer";
import { templateReducer } from "./redux/templates/reducer";
import { templateEditorReducer } from "./redux/templateEditor/reducer";
import authentificationReducer from "./redux/authentification/reducer";
import { dropDownsReducer } from "./redux/dropdowns/reducer";
import { tableModelsReducer } from "./redux/tablemodels/reducer";
import { conditionalFieldsReducer } from "./redux/conditionalField/reducer";
var axios = require("axios");

// Create redux store
const reducers = combineReducers(
  Object.assign(
    {},
    {
      authentification: authentificationReducer,
      common: commonReducer,
      missionResources: missionReducer,
      templateResources: templateReducer,
      userResources: userReducer,
      templateEditor: templateEditorReducer,
      dropDownResources: dropDownsReducer,
      tableModelsResources: tableModelsReducer,
      conditionalFieldResources: conditionalFieldsReducer,
    },
  ),
);
const windowIfDefined = typeof window === "undefined" ? null : window;
const composeEnhancers =
  windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunkMiddleware)),
);

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import("./containers/DefaultLayout"),
  loading,
});

// Pages
const Login = Loadable({
  loader: () => import("./views/Pages/Login"),
  loading,
});

const Register = Loadable({
  loader: () => import("./views/Pages/Register"),
  loading,
});

const Page404 = Loadable({
  loader: () => import("./views/Pages/Page404"),
  loading,
});

const Page500 = Loadable({
  loader: () => import("./views/Pages/Page500"),
  loading,
});

class App extends Component {
  componentDidMount() {
    const sessionToken = sessionStorage.getItem("sessionToken");
    if (sessionToken) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${sessionToken}`;
    }
    axios.defaults.headers.common["Type-Application"] = "Web";
    axios.defaults.validateStatus = (status) => {
      return (status >= 200 && status < 300) || (status === 403);
    }
    axios.interceptors.response.use((res) => {
      if (res?.status === 403) {
        sessionStorage.removeItem("sessionToken");
        window.location.href = "/";
      }
      return res;
    }, (err) => {
      console.log(">>>>>>", err)
      return Promise.reject(err);
    });
  }
  render() {
    return (
      <Provider store={store}>
        <HashRouter>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route
              exact
              path="/register"
              name="Register Page"
              component={Register}
            />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </HashRouter>
      </Provider>
    );
  }
}

export default App;
