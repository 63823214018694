const deleteObjectProperties = (object, path) => {
  if (path.length > 1) {
    const newobject = object[path.shift()];
    deleteObjectProperties(newobject, path);
  } else {
    delete object[path[0]];
  }
};

export default deleteObjectProperties;
