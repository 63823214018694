import Immutable from "seamless-immutable";
import { SET_CONDITIONALFIELDS, SET_CONDITIONALFIELDS_PAGE } from "./action";
const initialState = {
  conditionalFields: Immutable([]),
  page: {
    size: 20,
    totalElements: undefined,
    number: undefined,
  },
};

export const conditionalFieldsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONDITIONALFIELDS:
      return Immutable.setIn(
        state,
        ["conditionalFields"],
        action.conditionalFields,
      );
    case SET_CONDITIONALFIELDS_PAGE:
      return Immutable.setIn(state, ["page"], action.page);
    default:
      return state;
  }
};
