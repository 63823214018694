import Immutable from "seamless-immutable";
import {
  SET_ACTIVE_PART,
  DELETE_SCHEMA,
  UPDATE_SCHEMA,
  ADD_SCHEMA,
  SET_JSONSCHEMA,
  SET_UISCHEMA,
  MOVE_SECTION_SCHEMA,
} from "./action";
import _ from "lodash";
import deleteObjectProperties from "./../../utils/deleteObjectProperties";

const initialState = Immutable({
  activePart: undefined,
  jsonSchema: {
    type: "object",
    properties: {},
  },
  uiSchema: {
    "ui:field": "CustomSchemaField",
  },
});

export function templateEditorReducer(state = initialState, action) {
  switch (action.type) {
    case SET_JSONSCHEMA:
      return {
        ...state,
        jsonSchema: action.jsonSchema,
      };
    case SET_UISCHEMA:
      return {
        ...state,
        uiSchema: action.uiSchema,
      };
    case DELETE_SCHEMA:
      const uiPath = action.path.filter(key => key !== "properties");
      let deletedJsonSchema = _.cloneDeep(state.jsonSchema);
      let deletedUiSchema = _.cloneDeep(state.uiSchema);
      deleteObjectProperties(deletedJsonSchema, action.path);
      if (_.has(deletedUiSchema, uiPath)) {
        deleteObjectProperties(deletedUiSchema, uiPath);
      }
      return {
        ...state,
        jsonSchema: deletedJsonSchema,
        uiSchema: deletedUiSchema,
      };
    case ADD_SCHEMA:
      const modifiedJsonSchema = Immutable.setIn(
        state.jsonSchema,
        action.path,
        action.schema,
      );
      if (!action.uiSchema) {
        return {
          ...state,
          jsonSchema: modifiedJsonSchema,
        };
      }
      const uiPath2 = action.path.filter(key => key !== "properties");
      const modifiedUiSchema = Immutable.setIn(
        state.jsonSchema,
        uiPath2,
        action.uiSchema,
      );
      return {
        ...state,
        jsonSchema: modifiedJsonSchema,
        uiSchema: modifiedUiSchema,
      };
    case MOVE_SECTION_SCHEMA:
      let movingSchema = _.cloneDeep(state.jsonSchema);
      let movingKeys = Object.keys(_.get(movingSchema, action.path));
      let movingKey = movingKeys.splice(action.srcIndex, 1);

      let movedSchema = {};
      movingKeys.forEach((key, index) => {
        if (index === action.destIndex) {
          movedSchema[movingKey] = _.get(movingSchema, [
            ...action.path,
            movingKey,
          ]);
        }
        movedSchema[key] = _.get(movingSchema, [...action.path, key]);
      });
      if (action.destIndex === movingKeys.length) {
        movedSchema[movingKey] = _.get(movingSchema, [
          ...action.path,
          movingKey,
        ]);
      }

      _.set(movingSchema, action.path, movedSchema);
      return {
        ...state,
        jsonSchema: movingSchema,
      };
    case UPDATE_SCHEMA:
      let path = _.cloneDeep(action.oldPath);
      let newKey = action.newPath[action.newPath.length - 1];
      const oldKey = path.pop();
      let tmpJsonSchema = {};
      const subSchema = _.get(state.jsonSchema, path);
      const keys = Object.keys(subSchema);
      keys.forEach(key => {
        if (key !== oldKey) {
          tmpJsonSchema[key] = subSchema[key];
        } else {
          tmpJsonSchema[newKey] = action.field;
        }
      });
      const updatedJsonSchema = Immutable.setIn(
        state.jsonSchema,
        path,
        tmpJsonSchema,
      );

      const uiOldPath = action.oldPath.filter(key => key !== "properties");
      const uiNewPath = action.newPath.filter(key => key !== "properties");
      let updatedUiSchema = _.cloneDeep(state.uiSchema);
      _.set(updatedUiSchema, uiNewPath, action.uiSchema);
      if (
        _.has(updatedUiSchema, uiOldPath) &&
        !_.isEqual(uiOldPath, uiNewPath)
      ) {
        deleteObjectProperties(updatedUiSchema, [...uiOldPath]);
      }
      return {
        ...state,
        jsonSchema: updatedJsonSchema,
        uiSchema: updatedUiSchema,
      };
    case SET_ACTIVE_PART:
      return {
        ...state,
        activePart: action.activePart,
      };
    default:
      return state;
  }
}
