export const ADD_USER = "ADD_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_USERS = "SET_USERS";
export const SET_USER_PAGE = "SET_USER_PAGE";
export const EDIT_USER = "EDIT_USER";
export const REMOVE_USER = "REMOVE_USER";

export const removeUser = id => {
  return {
    type: REMOVE_USER,
    id: id,
  };
};
export const editUser = user => {
  return {
    type: EDIT_USER,
    user: user,
  };
};
export const setUserPage = page => {
  return {
    type: SET_USER_PAGE,
    page: page,
  };
};
export const addUser = user => {
  return {
    type: ADD_USER,
    user: user,
  };
};

export const setUsers = users => {
  return {
    type: SET_USERS,
    users: users,
  };
};

export const setCurrentUser = user => {
  return {
    type: SET_CURRENT_USER,
    user,
  };
};
