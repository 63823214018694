import { ADD_TEMPLATE, SET_TEMPLATES, SET_TEMPLATE_PAGE, ALL_TEMPLATE,ALL_DOCUMENT } from "./actions";
import Immutable from "seamless-immutable";
/** Initial state */
const EMPTY_ARRAY = Immutable([]);
const initialState = {
  templates: EMPTY_ARRAY,
  documents: EMPTY_ARRAY,
  template_all: EMPTY_ARRAY,
  page: {
    size: 20,
    totalElements: undefined,
    totalPages: undefined,
    number: undefined,
  },
};

export function templateReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TEMPLATE:
      return Immutable.set(
        state,
        "templates",
        state.templates.concat(action.template)
      );
    case SET_TEMPLATES:
      return Immutable.set(state, "templates", action.templates);
    case ALL_DOCUMENT:
      return Immutable.set(state, "documents", action.documents);
    case ALL_TEMPLATE:
      return Immutable.set(state, "template_all", action.templates);
    case SET_TEMPLATE_PAGE:
      return Immutable.set(state, "page", action.page);
    default:
      return state;
  }
}
