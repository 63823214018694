export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const loginUser = (sessionToken) => {
    return {
        type: LOGIN,
        sessionToken: sessionToken
    }
}

export const logoutUser = () => {
    return {
        type: LOGOUT
    }
}